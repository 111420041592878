import "../styles/Header.scss"
import "../styles/Switch.scss"
// import arrows from "../assets/arrows_date.svg"
import {  useState, useEffect } from "react";
import { useLocation } from 'react-router-dom'
import { getSharedWallets } from "../services/getSharedWallets";
import { getWallets } from "../services/getWallets";
import { getMonths } from "../services/getMonths";

function Header() {

  const [profile, setProfile] = useState("")
  const [perfil, setPerfil] = useState("")
  const [totalProductos, setTotalProductos] = useState(0)
  const [totalDeudas, setTotalDeudas] = useState(0)
  const [adeudados, setAdeudados] = useState(0)
  const [prefCurrency, setPrefCurrency] = useState(1)
  const [budget, setBudget] = useState(0)
  const [isChecked, setIsChecked] = useState(window.localStorage.getItem("light") ? true : false)
  const location = useLocation();

  useEffect(() => {
    // setLoader(true)
    if (!window.localStorage.getItem("walletId")) {
      setPrefCurrency(1)
      
    } else if(window.localStorage.getItem("compartida") && location.pathname === "/categorias") {
      const WALLET_ID = window.localStorage.getItem("walletId")
      getSharedWallets()
      .then(data => setPrefCurrency(data.filter(wallet => wallet.id === Number(WALLET_ID))[0].idcurrency))
    } else if (!window.localStorage.getItem("compartida") && location.pathname === "/categorias") {
      const WALLET_ID = window.localStorage.getItem("walletId")
      getWallets()
      .then(data => setPrefCurrency(data.filter(wallet => wallet.id === Number(WALLET_ID))[0]?.idcurrency))

    }
    // .then(data => console.log(data))
    // .finally(setLoader(false))
  },[location])
  
  useEffect(() => {
    // setLoader(true)
    if (!window.localStorage.getItem("walletId")) {
      setPrefCurrency(1)
      
    } else if(window.localStorage.getItem("compartida") && location.pathname === "/productos") {
      const WALLET_ID = window.localStorage.getItem("walletId")
      getSharedWallets()
      .then(data => setPrefCurrency(data.filter(wallet => wallet.id === Number(WALLET_ID))[0].idcurrency))
    } else if (!window.localStorage.getItem("compartida") && location.pathname === "/productos") {
      const WALLET_ID = window.localStorage.getItem("walletId")
      getWallets()
      .then(data => setPrefCurrency(data.filter(wallet => wallet.id === Number(WALLET_ID))[0]?.idcurrency))

    }
    // .then(data => console.log(data))
    // .finally(setLoader(false))
  },[location])

  useEffect(() => {
    if(window.localStorage.getItem("walletId") && location.pathname === '/productos'){
      const TOKEN = window.localStorage.getItem("loggedToken")
      const WALLET_ID = window.localStorage.getItem("walletId")
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/product/wal/${WALLET_ID}/`;
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Token ${JSON.parse(TOKEN)}`
      };
  
      fetch(url,{ headers, withCredentials:true })
      .then(response => response.json())
      .then(data => {setAdeudados(data.reduce((sum, item) => sum + Number(item.total), 0))})
    } 
  },[location]);

  useEffect(() => {
    // setLoader(true)
    if(location.pathname === '/productos'){
      getMonths()
      .then(data => {
        if(data?.months.length === 0){
          calcDeudas(0)
        } else {
          calcDeudas(Number(window.localStorage.getItem("monthId")))
        }})
    }
  },[location])

  useEffect(() => {
    if (!window.localStorage.getItem("walletId")) {
      const PROFILE_ID = window.localStorage.getItem("userName")
      setProfile(JSON.parse(PROFILE_ID))
      
    } else {
      // const PROFILE_ID = window.localStorage.getItem("userName")
      if(window.localStorage.getItem("compartida")){
        const WALLET_ID = window.localStorage.getItem("walletId")
        getSharedWallets()
        .then(data => setProfile(data.filter(wallet => wallet.id === Number(WALLET_ID))[0]?.name))

      } else {
        const WALLET_ID = window.localStorage.getItem("walletId")
        getWallets()
        .then(data => setProfile(data.filter(wallet => wallet.id === Number(WALLET_ID))[0]?.name))
      }
      // setProfile(JSON.parse(PROFILE_ID))

    }



  },[])

  useEffect(() => {
    // setLoader(true)
    if(location.pathname === "/categorias"){
        getMonths()
        .then(data => {
          // console.log(data);
          if(data?.months.length === 0){
              BringDatos2(0)
            } else {
              BringDatos2(Number(window.localStorage.getItem("monthId")))
          }
      })
    }
    // .finally(        BringDatos())
  },[location])

      useEffect(() => {
      const PROFILE_ID = window.localStorage.getItem("userName")
      setPerfil(JSON.parse(PROFILE_ID))
    },[])

    async function calcDeudas(id){
  
      const PROFILE_ID = window.localStorage.getItem("profileId")  
      const TOKEN = window.localStorage.getItem("loggedToken")
      const WALLET_ID = window.localStorage.getItem("walletId")
    
      const formData = {month_id:id , id:Number(PROFILE_ID), wallet_id:Number(WALLET_ID)}
      // console.log(formData);
    
      if(id === 0){
          setTotalDeudas([])
      } else {
          try {
              const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/byprod/`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Token ${JSON.parse(TOKEN)}`
                },
                withCredentials:true,
                body: JSON.stringify(formData),
              });
              
              if (response.status === 200) {
              //   setLoader(false)
                const data = await response.json();
                // data.pop()
                // console.log(data);
                setTotalProductos(data.reduce((sum, item) => sum + Number(item.original_total), 0));
                setTotalDeudas(data.reduce((sum, item) => sum + Number(item.total_sum), 0));
                            
                  // navigate("/home")
                } else {
                  // setLoader(false)
                  // setShowLoginError(true)
                  console.error('Error submitting form');
                }
              } catch (error) {
                console.error('Error:', error);
              }
            }
      }

      function renderCurrency(){
        if(prefCurrency === 1){
          return "USD"
        } else if (prefCurrency === 2){
          return "MXN"
        } else {
          return "EUR"
        }
      }
      async function BringDatos2(id){

        const PROFILE_ID = window.localStorage.getItem("profileId")  
        const TOKEN = window.localStorage.getItem("loggedToken")
        const WALLET_ID = window.localStorage.getItem("walletId")
        
        if (WALLET_ID && id !== 0) {
          if(window.localStorage.getItem("products")){
            try {
              const formData = {idMonth:id , idWallet:Number(WALLET_ID)}
              // console.log(formData);
              
              const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/budget/`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Token ${JSON.parse(TOKEN)}`
                },
                withCredentials:true,
                body: JSON.stringify(formData),
              });
              
              if (response.status === 200) {
              //   setLoader(false)
                const data = await response.json();
                // console.log(data);
                
                setBudget(data.budget);
                // setEgresos(data[data.length - 1].negative_total_sum);
                // setIngresos(data[data.length - 1].positive_total_sum);
                // setTotalSum(data[data.length - 1].total_sum);
    
      
                  // navigate("/home")
                } else {
                  // setLoader(false)
                  // setShowLoginError(true)
                  console.error('Error submitting form');
                }
              } catch (error) {
                console.error('Error:', error);
              }
          } else {
            try {
              const formData = {month_id:id , wallet_id:WALLET_ID}
              const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/transactions/wallet/`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Token ${JSON.parse(TOKEN)}`
                },
                withCredentials:true,
                body: JSON.stringify(formData),
              });
              
              if (response.status === 200) {
              //   setLoader(false)
                const data = await response.json();
                // console.log(data);
                
                setBudget(data[data.length -1].total_sum)
    
    
      
                  // navigate("/home")
                } else {
                  // setLoader(false)
                  // setShowLoginError(true)
                  console.error('Error submitting form');
                }
              } catch (error) {
                console.error('Error:', error);
              }
          }
        } else {
          if(id === 0){
        } else {
          try {        
            const formData = {month_id:id , profile_id:Number(PROFILE_ID), wallet_id:WALLET_ID}
    
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/transactions/bywal/`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${JSON.parse(TOKEN)}`
              },
              withCredentials:true,
              body: JSON.stringify(formData),
            });
            
            if (response.status === 200) {
            //   setLoader(false)
              const data = await response.json();
              console.log(data)
    
    
    
                // navigate("/home")
              } else {
                // setLoader(false)
                // setShowLoginError(true)
                console.error('Error submitting form');
              }
            } catch (error) {
              console.error('Error:', error);
            }
          }
        }
      
    
        }

      const handleCheckboxChange = () => { 
        setIsChecked(!isChecked); 
        if(isChecked){
          window.localStorage.removeItem("light")
          window.location.reload()
        } else {
          window.localStorage.setItem("light", true)
          window.location.reload()
        }
      };


    // useEffect(() => {
    //     const TOKEN = window.localStorage.getItem("loggedToken")
    //     const PROFILE_ID = window.localStorage.getItem("profileId")
    
    //     const url = `${process.env.REACT_APP_BACKEND_URL}/api/months/${PROFILE_ID}/`;
    //     const headers = {
    //       'Content-Type': 'application/json',
    //       'Authorization': `Token ${JSON.parse(TOKEN)}`
    //     };
    
    //     fetch(url,{ headers, withCredentials:true })
    //     .then(response => response.json())
    //     .then(data => setMonths(data.months[data.months.length-1]))
    // },[])

    
    // useEffect(() => {
    //   const PROFILE_ID = window.localStorage.getItem("profileId")
    //   const TOKEN = window.localStorage.getItem("loggedToken")
  
    //   const url = `${process.env.REACT_APP_BACKEND_URL}/api/profiles/`;
    //   const headers = {
    //     'Content-Type': 'application/json',
    //     'Authorization': `Token ${JSON.parse(TOKEN)}`
    //   };
  
    //   fetch(url,{ headers, withCredentials:true })
    //   .then(response => response.json())
    //   .then(data => BringDatos(data.filter(user => user.id === Number(PROFILE_ID))[0].image))
    // },[])

    // async function BringDatos(name){
    //   setProfileImg(`${process.env.REACT_APP_BACKEND_URL}${name}`)
    //   }

    // const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
    // const fecha = new Date()
    // const dia = fecha.getDate();
    // const mes = fecha.getMonth() ;
    // const año = fecha.getFullYear();


  return (
    <header className={window.localStorage.getItem("light") ? "web_header_light":"web_header"}>
        <h1>{window.localStorage.getItem("walletId")? "Visualizando":"Bienvenido"} "<span>{profile}</span>"</h1>
            {/* <img src={arrows} alt="flechas" />
            <span>{meses[mes]} {dia}, {año}</span> */}
        {/* <div className="date_container">
        </div> */}
        {location.pathname === '/productos'
          ? 
          <>
              <div className="balance_cat_header">
                <p>Total de Productos: <span>{renderCurrency()}${totalProductos.toLocaleString("es-MX")}</span></p>
              </div>
              <div className="balance_cat_header">
                <p>Total Adeudado: <span>{renderCurrency()}${adeudados.toLocaleString("es-MX")}</span></p>
              </div>
              <div className="balance_cat_header">
                <p>Total Abonado: <span>{renderCurrency()}${totalDeudas.toLocaleString("es-MX")}</span></p>
              </div>
          </>
          : ""
        }
        {location.pathname === '/categorias'
          ? 
          <div className="balance_cat_header">
            <p>Mi Balance: <span>{renderCurrency()}${budget.toLocaleString('es-MX')}</span></p>
          </div>
          : ""
        }
        <div className="end_header">
          <label className="switch"> 
            <input 
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            /> 
            <span className="slider"></span> 
          </label>
          <select>
            <option>{perfil}</option>
          </select>
        </div>
    </header>
  )
}

export default Header