import React from 'react'
import spinner from "../assets/Rolling@1x-1.0s-200px-200px.svg"
import "../styles/Loading.scss"

function Loading() {
  return (
    <div className='loading_container'>
      <img src={spinner} alt="spinner" />
    </div>
  )
}

export default Loading