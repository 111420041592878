export async function getMonths(){
    if (window.localStorage.getItem("loggedToken")) {
        const TOKEN = window.localStorage.getItem("loggedToken")
        const PROFILE_ID = window.localStorage.getItem("profileId")
        const WALLET_ID = window.localStorage.getItem("walletId")
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/months/${PROFILE_ID}/${WALLET_ID}/`;
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${JSON.parse(TOKEN)}`
        };
    
        try {
            const response = await fetch(url, { headers, withCredentials: true });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
            return null; // Or handle the error as you need
        }
    }

}