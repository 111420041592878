import { useState } from "react";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
// import google_icon from "../assets/google_icon.svg";
// import upload_img from "../assets/upload_img.svg";
import cross_exit from "../assets/cross_exit.svg";
import "../styles/CrearCuenta.scss"
import { Link } from "react-router-dom";
import Loading from "./Loading";



function CrearCuenta() {

    const [viewPassword, setViewPassword] = useState(false);
    const [viewConfirmPassword, setViewConfirmPassword] = useState(false);

    const [newUserName, setNewUserName] = useState("");
    const [newUserMail, setNewUserMail] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [imgUrl/*, setImgUrl*/] = useState("")
    // const [showImg, setShowImg] = useState(false)

    const [passwordError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showFailed, setShowFailed] = useState(false);
    const [loader, setLoader] = useState(false);

    // useEffect(() => {
    //     HandleChange()
    // },[passwordConfirmation])

    // const [currency, setCurrency] = useState(0);

    // useEffect(() => {
    //     const url = `${process.env.REACT_APP_BACKEND_URL}/api/currencies/`;
    //     const headers = {
    //       'Content-Type': 'application/json',
    //     };
    
    //     fetch(url,{ headers, withCredentials:true })
    //     .then(response => response.json())
    //     .then(data => setCurrencies(data))
    // },[])

    // const HandleChange = (e) => {
    //     const { name, value } = e.target;
    //     if (name === 'password') {
    //         setNewPassword(value);
    //     } else if (name === 'confirmPassword') {
    //         setPasswordConfirmation(value);
    //     } 
    //     setPasswordError(newPassword === value);
    //     console.log(newPassword);
    //     console.log(passwordConfirmation);
    //   };

    const handlePasswordChange = (e) => {
        setNewPassword(e.target.value);
      };
    
      const handleConfirmPasswordChange = (e) => {
        setPasswordConfirmation(e.target.value);
      };

    function HandleViewPassword(){
        setViewPassword(!viewPassword)
    }

    function HandleViewConfirmPassword(){
        setViewConfirmPassword(!viewConfirmPassword)
    }

    // const handleFileChange = (e) => {
    //     const file = e.target.files[0];
    //     // const url = URL.createObjectURL(file);
    //     setImgUrl(file);
    //     setShowImg(true)
    //     // console.log(imgUrl);
    //   }

    //   function handleCurrencyChange(e){
    //     const selectedWallet = e.target.options[e.target.selectedIndex];
    //     const id = selectedWallet.getAttribute('value');
    //     setCurrency(id);
    //     console.log(selectedWallet);
    //     console.log(id);
    // }

    async function handleSubmit(e){
        e.preventDefault();
        setLoader(true)
        if(!passwordError){
            const formData = {username: newUserName, password:newPassword, email:newUserMail}
            console.log(formData);
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/register/`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  withCredentials:true,
                  body: JSON.stringify(formData),
                });
            
                if (response.status === 201) {
                    setLoader(false)
                    setShowSuccess(true)
                    const data = await response.json();
                    console.log(data);
                    const postData = new FormData();
                    postData.append('image', imgUrl);
                      try {
                          fetch(`${process.env.REACT_APP_BACKEND_URL}/api/profiles/img/${data.profile_id}/`, {
                          method: 'POST',
                          // headers: {
                          //   // 'Content-Type': 'multipart/form-data',
                          //   // 'Authorization': `Token ${JSON.parse(TOKEN)}`
                          // },
                          withCredentials:true,
                          body: postData,
                        })
                        .then(response => response.json())
                        .then(dato => console.log(dato))
        
                      } catch (error) {
                        console.error('Error:', error);
                      }

                } else {
                    console.log(response);
                    setLoader(false)
                    setShowFailed(true)
                }
              } catch (error) {
                console.error('Error:', error);
              }
        } else {
            setLoader(false)
            setShowFailed(true)
        }

       
    }

  return (
    <>
        <div className='crear'>
            {/* <div className="left_bg"></div> */}
            <div className='crear_container'>
                <form onSubmit={handleSubmit}>
                    <h1>Registrate</h1>
                    {/* <div className="select_profile" onClick={()=>document.querySelector(".profile_pic").click()}>
                        {showImg
                            ? <img className="url_img" src={URL.createObjectURL(imgUrl)} alt="upload profile" />
                            : <img src={upload_img} alt="upload profile" />
                        }
                        <input onChange={handleFileChange} className="profile_pic" type="file" accept="image/*" />
                    </div> */}
                    <div className='input_name'>
                        <label htmlFor="newUser">Nombre</label>
                        <input
                            id="newUser"
                            type="text" 
                            placeholder='Nombre...' 
                            required
                            value={newUserName}
                            onChange={(e) => setNewUserName(e.target.value)}
                        />
                    </div>
                    <div className='input_email'>
                        <label htmlFor="newEmail">Correo electrónico</label>
                        <input 
                            id="newEmail"
                            type="email" 
                            placeholder='Correo electrónico...' 
                            required
                            value={newUserMail}
                            onChange={(e) => setNewUserMail(e.target.value)}
                        />
                    </div>
                    <div className='input_contraseña'>
                        <div className='flex_label'>
                            <label htmlFor="newPassword">Contraseña</label>
                        </div>
                        <div className='flex_input_eye'>
                            <input 
                                id="newPassword"
                                name="password"
                                type={viewPassword ? "text" : "password"} 
                                placeholder='Contraseña...' 
                                required
                                value={newPassword} 
                                onChange={handlePasswordChange}
                            />
                            {viewPassword 
                            ? <IoEyeOutline onClick={HandleViewPassword} className='icon' /> 
                            : <IoEyeOffOutline onClick={HandleViewPassword} className='icon' />}
                        </div>
                    </div>
                    <div className='input_contraseña'>
                        <div className='flex_label'>
                            <label htmlFor="confirmPassword">Confirmar Contraseña</label>
                        </div>
                        <div className='flex_input_eye'>
                            <input 
                                id="confirmPassword"
                                name="confirmPassword"
                                type={viewConfirmPassword ? "text" : "password"} 
                                placeholder='Confirmar contraseña...' 
                                required
                                value={passwordConfirmation} 
                                onChange={handleConfirmPasswordChange}
                            />
                            {viewConfirmPassword 
                            ? <IoEyeOutline onClick={HandleViewConfirmPassword} className='icon' /> 
                            : <IoEyeOffOutline onClick={HandleViewConfirmPassword} className='icon' />}
                        </div>
                        {newPassword !== passwordConfirmation ? <p style={{color:"red"}}>La contraseña no coincide</p>: ""}
                    </div>
                    {/* <div className="pref_moneda">
                        <p>Moneda de preferencia</p>
                        <select onChange={handleCurrencyChange}>
                            <option value={1} disabled selected>Tipo de moneda</option>
                            <option value={1}>USD</option>
                            <option value={2}>MXN</option>
                            <option value={3}>EUR</option>
                        </select>
                    </div> */}
                    <button className='login_btn' type="submit">Registrate</button>
                    {/* <div className='iniciar_con'>
                        <div className='iniciar_linea'></div>
                        <p>iniciar con</p>
                    </div>
                    <button className='login_google'><img className='google_icon' src={google_icon} alt="google icon" />Continue with Google</button> */}
                    <div className='crear_cuenta'>
                        <span>¿Ya tienes cuenta?</span>
                        <Link to="/login"><p>Iniciar Sesión</p></Link>
                    </div>
                </form>
            </div>
        </div>
        {showSuccess 
            ? <RegistroExitoso show={showSuccess} setShow={setShowSuccess} />
            : "" } 
        {showFailed 
            ? <RegistroFallido show={showFailed} setShow={setShowFailed} />
            : "" } 
        {loader 
            ? <Loading />
            : ""}
        
    </>
  )
}

export default CrearCuenta


function RegistroExitoso({show, setShow}) {

  return (
    <div className="registro_exitoso_container">
        <div className="reg_subcontainer">
            <img onClick={() => setShow(!show)} src={cross_exit} alt="cerrar" />
            <h2>¡Registro Exitoso!</h2>
            <p>Usted se ha podido registrar correctamente.</p>
            <Link onClick={() => setShow(!show)} to="/login"><button>Iniciar Sesión</button></Link>
        </div>
    </div>
  )
}

function RegistroFallido({show, setShow}) {

  return (
    <div className="registro_exitoso_container">
        <div className="reg_subcontainer">
            <img onClick={() => setShow(!show)} src={cross_exit} alt="cerrar" />
            <h2>Registro Fallido</h2>
            <p>Ah ocurrido un error al momento de registrarse.</p>
        </div>
    </div>
  )
}
