import React, { useEffect, useState } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import "../styles/Resumen.scss"
import { getSharedWallets } from '../services/getSharedWallets';
import { getWallets } from '../services/getWallets';

function AccountSummary() {
    const [accountData, setAccountData] = useState(null);
    const [profile, setProfile] = useState("");
    const [transac_resumen, setTransac_resumen] = useState([]);

    useEffect(() => {
      if(window.localStorage.getItem("compartida")){
        const WALLET_ID = window.localStorage.getItem("walletId")
        getSharedWallets()
        .then(data => setProfile(data.filter(wallet => wallet.id === Number(WALLET_ID))[0]?.name))

      } else {
        const WALLET_ID = window.localStorage.getItem("walletId")
        getWallets()
        .then(data => setProfile(data.filter(wallet => wallet.id === Number(WALLET_ID))[0]?.name))
      }
    }, []);
    useEffect(() => {
        // Simulación de llamada al backend
        async function DescargaArchivo(){
            const TOKEN = window.localStorage.getItem("loggedToken")
            // const WALLET_ID = window.localStorage.getItem("walletId")
            const url = `${process.env.REACT_APP_BACKEND_URL}/api/encrypt/`;
            const headers = {
              'Content-Type': 'application/json',
              'Authorization': `Token ${JSON.parse(TOKEN)}`
            };
        
            await fetch(url,{ headers, withCredentials:true })
            .then(response => response.json())
            .then(data => ArchivoFinal(data))
          }

          DescargaArchivo();
    }, []);

    async function ArchivoFinal(encrypt){
        const TOKEN = window.localStorage.getItem("loggedToken")
        // const WALLET_ID = window.localStorage.getItem("walletId")
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/export/raw/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          },
          withCredentials:true,
          body: JSON.stringify({encrypted_user_id:encrypt, wallet_id:Number(window.localStorage.getItem("walletId")),month_id:Number(window.localStorage.getItem("monthId"))})
        });
        if (response.status === 204 || response.status === 200) {
          // setLoader(false)
          console.log('Submit successfully');
          // console.log(response);
          // window.location.reload()
          const data = await response.json();
          // console.log(data);
          setAccountData(data);
          setTransac_resumen(data.transactions)
          
            // const url = window.URL.createObjectURL(new Blob([blob]));
            // const link = document.createElement('a');
            // link.href = url;
            // console.log(url);
            
            // link.setAttribute('download', 'transactions_export.xlsx');
            // document.body.appendChild(link);
            // link.click();
            // link.parentNode.removeChild(link);
        
        } else {
            console.log(response.status);
            // setLoader(false)
            // setShowLoginError(true)
            console.error('Error submitting form');
          }
      } catch (error) {
        console.log(error);
      }
      }

      const generatePDF = async () => { 
        const element = document.getElementById('account-summary'); 
        const canvas = await html2canvas(element); 
        const imgData = canvas.toDataURL('image/png'); 
        const pdf = new jsPDF(); 
        const pageHeight = pdf.internal.pageSize.height;                 
        const pageWidth = pdf.internal.pageSize.width; 
        const imgWidth = pageWidth; 
        const imgHeight = (canvas.height * imgWidth) / canvas.width;  
               
        let heightLeft = imgHeight; 
        let position = 0; 
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight); 
        heightLeft -= pageHeight; 
        while (heightLeft > 0) { 
          position = heightLeft - imgHeight; 
          pdf.addPage(); 
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight); 
          heightLeft -= pageHeight; 
        } 
        pdf.save('resumen-cuenta.pdf'); 
      };

      const obtenerFechaActual = () => { 
        const hoy = new Date(); 
        const dia = String(hoy.getDate()).padStart(2, '0'); 
        const mes = String(hoy.getMonth() + 1).padStart(2, '0'); // Enero es 0 
        const año = hoy.getFullYear(); 
        
        return `${dia}-${mes}-${año}`; 
      };

    return (
        <div className='full_container_summary'>
            <div id="account-summary">
                {accountData ? (
                    <div>
                        <h2 className='title_maslana'>MASLANA</h2>
                        <div className='estado'>
                          <h1>Estado de Cuenta</h1>
                          <div className="flex_estado">
                            <div className="estado_item1">
                              <span>Nombre de usuario</span>
                              <span>Nombre de cuenta</span>
                              <span>Fecha</span>
                            </div>
                            <div className="estado_item1">
                              <p>{JSON.parse(window.localStorage.getItem("userName"))}</p>
                              <p>{profile}</p>
                              <p>{accountData?.month.start} - {obtenerFechaActual()}</p>
                            </div>
                          </div>
                        </div>
                        <div className='bancario'>
                          <h1>Resumen Bancario</h1>
                          <div className='tabla_bancario'>
                            <div className="tabla_resumen_header">
                              <span>Producto</span>
                              <span>Total Ingresos</span>
                              <span>Total Egresos</span>
                              <span>Total Vendido</span>
                              <span>Total a Pagar</span>
                            </div>
                            <ul>
                              {accountData?.category_totals?.map((cat) => (
                                <li className="tabla_resumen">
                                    <span>{cat.category_name}</span>
                                    <span>${cat.total_positive}</span>
                                    <span>${cat.total_negative}</span>
                                    <span>${cat.total_sold}</span>
                                    <span>${cat.total_to_pay}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                          {/* <div className='tabla_bancario'>
                            <div className="tabla_resumen_header">
                              <span>Pendientes</span>
                            </div>
                            <ul>
                              {accountData?.category_totals?.map((cat) => {
                                if (cat.total_pending !== 0) {
                                  return (
                                    <li className="tabla_resumen">
                                        <span>{cat.category_name}</span>
                                        <span>${cat.total_pending_positive}</span>
                                        <span>${cat.total_pending_negative}</span>
                                        <span>${cat.total_pending}</span>
                                    </li>
                                  )
                                } else {
                                  return null
                                }
                              })}
                            </ul>
                          </div> */}
                        </div>
                        <div className="movimientos">
                          <h1>Movimientos de la cuenta</h1>
                          <div className='tabla_bancario'>
                            <div className="tabla_resumen_header">
                              <span>Cuenta</span>
                              <span>Fecha</span>
                              <span>Moneda</span>
                              <span>Monto</span>
                              <span>Usuario</span>
                              <span>Producto</span>
                            </div>
                            <ul>
                              {transac_resumen?.map((tran) => (
                                <li className="tabla_resumen">
                                    <span>{tran.Wallet}</span>
                                    <span>{tran.Date}</span>
                                    <span>{accountData?.currency}</span>
                                    <span>${tran.Balance}</span>
                                    <span>{tran.Name}</span>
                                    <span>{tran.Product}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        {/* Añade aquí más detalles según sea necesario */}
                    </div>
                ) : (
                    <p>Cargando...</p>
                )}
            </div>
            <div className="fondo_btn">
              <button className='descarga_resumen' onClick={generatePDF}>Descargar Resumen PDF</button>
            </div>
        </div>
    );
}

export default AccountSummary;
