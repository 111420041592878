import React from 'react'

function RegistroBitacora({id, fecha, accion, usuario, comentario, billeteras, idBilletera, billeterasComparidas}) {

    const filtroBilletera = billeteras?.filter(bil => bil.id === Number(idBilletera))[0]?.name
    const filtroBilleteraCompartida = billeterasComparidas?.filter(bil => bil.id === Number(idBilletera))[0]?.name

  return (
    <li key={id}>
        <p className='bitac_1'><span>Fecha:</span> {fecha}</p>
        <p className='bitac_2'><span>Accion:</span> {accion}</p>
        <p className='bitac_3'><span>Usuario:</span> {usuario}</p>
        <p className='bitac_4'><span>Billetera:</span> {filtroBilletera ? filtroBilletera : filtroBilleteraCompartida}</p>
        <p className='bitac_5'><span>Comentario:</span> {comentario === null || comentario === "" ? "Ninguno" : comentario}</p>
    </li>
  )
}

export default RegistroBitacora