import React from 'react'
import "../styles/Perfiles.scss"
import { useNavigate } from "react-router-dom";

function Invitacion() {

    const navigate = useNavigate();

    // function showLal(){
    //     const keyWord = "add/";
    //     const INVITE = window.location.href
    //     const posicion = INVITE.indexOf(keyWord)
    //     const inviteId = INVITE.substring(posicion + keyWord.length).trim();
    //     console.log(inviteId);
    // }

    async function handleInvite(){
        // const PROFILE_ID = window.localStorage.getItem("profileId")
        const TOKEN = window.localStorage.getItem("loggedToken")
        const keyWord = "add/";
        const INVITE = window.location.href
        const posicion = INVITE.indexOf(keyWord)
        const inviteId = INVITE.substring(posicion + keyWord.length).trim();
        const formDatos = {
          encrypted_id: inviteId
        }

        if(Object.keys(localStorage).length !== 0){
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/share/add/`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${JSON.parse(TOKEN)}`
                  },
                  withCredentials:true,
                  body:JSON.stringify(formDatos)
                });
                
                if (response.status === 200) {
                  // setLoader(false)
                  console.log('Form submitted successfully');
                  const data = await response.json();
                  console.log(data);
                  navigate("/home");
                  } else {
                    console.log(response);
                    console.error('Error submitting form');
                  }
                } catch (error) {
                  console.error('Error:', error);
                }
        } else {
            navigate("/login")
        }


      }

    function handleVolver(){
      if(Object.keys(localStorage).length !== 0){
        navigate("/home")
      } else{
        navigate("/login")
      }
    }

  return (
    <div className="confirmacion_month_container">
          <div className="conf_subcontainer">
              {/* <img onClick={() => {setShow(!show)}} src={cross_exit} alt="cerrar" /> */}
              <h2 /*onClick={showLal}*/>¡Te han compartido una cuenta!</h2>
              <div className='flex_conf'>
                <button onClick={handleInvite} className='confirm_delete'>Aceptar</button>
                <button onClick={handleVolver} className='deny_delete' >Volver</button>
              </div>
          </div>
      </div>
  )
}

export default Invitacion