import React, { useEffect, useState } from 'react'
// import edit_row from "../assets/edit_row.svg"
import delete_icon from "../assets/delete_icon.svg"
import more_less from "../assets/more_less.svg"
import "../styles/Categorias.scss"
import { getMonths } from "../services/getMonths"
import { getWallets } from '../services/getWallets'

function TablaDisponibles({total, editable, setShowTransfer, setShowModify, showModify, setModifyId, pendiente, walletId,transacName, withCategory, showDate,setShowDate, setComments, profile_name, transactions, date, currAmount, comments, clave, idCategory, identificador, setApiUrl, showConfirmation, setShowConfirmation, setCategoryId, showComments, setShowComments}) {
    const [/*category*/, setCategory] = useState([{name:""}])
    const [/*walletName*/, setWalletName] = useState([{name:""}])
    // const [loader, setLoader] = useState(false)
    const [gastos, setGastos] = useState([])
  
    useEffect(() => {
        getMonths()
        .then(data => {
            if(data.months.length === 0){
                BringDatos(0)
            } else {
                BringDatos(Number(window.localStorage.getItem("monthId")))
            }
        })
        // .finally(        BringDatos())
        // eslint-disable-next-line
      },[])

    useEffect(() => {
      getWallets()
      .then(data => CombineCategories(data.filter(wall => wall.id === walletId)))
// eslint-disable-next-line
    },[])

    async function CombineCategories(local){

      // if(local.length === 0){
      //   setCompartido(true)
      // }

      const TOKEN = window.localStorage.getItem("loggedToken");
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/share/`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${JSON.parse(TOKEN)}`,
      };
  
      fetch(url, { headers, withCredentials: true })
        .then((response) => response.json())
        .then((data) => setWalletName(data.concat(local).filter(wall => wall.id === walletId)));

  }

  async function BringDatos(id){
  
    const PROFILE_ID = window.localStorage.getItem("profileId")  
    const TOKEN = window.localStorage.getItem("loggedToken")
  
    const formData = {month_id:id , id:Number(PROFILE_ID)}
    // console.log(formData);
  
    if(id === 0){
        setGastos([])
    } else {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/byprod/`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${JSON.parse(TOKEN)}`
              },
              withCredentials:true,
              body: JSON.stringify(formData),
            });
            
            if (response.status === 200) {
            //   setLoader(false)
              const data = await response.json();
              // data.pop()
              // console.log(data);
              setGastos(data.filter(prod => prod.product === clave));
    
                // navigate("/home")
              } else {
                // setLoader(false)
                // setShowLoginError(true)
                console.error('Error submitting form');
              }
            } catch (error) {
              console.error('Error:', error);
            }
          }
    }


    useEffect(() => {
        // setLoader(true)
        if(withCategory){
        const TOKEN = window.localStorage.getItem("loggedToken")
        const PROFILE_ID = window.localStorage.getItem("profileId")
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/categories/used/${PROFILE_ID}/`;
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${JSON.parse(TOKEN)}`
        };
    
        fetch(url,{ headers, withCredentials:true })
        .then(response => response.json())
        .then(data => setCategory(data.filter(cat => cat.id === idCategory)) || "")
        // .finally(console.log(category))
        } else {
          const TOKEN = window.localStorage.getItem("loggedToken")
          const url = `${process.env.REACT_APP_BACKEND_URL}/api/share/`;
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          };
      
          fetch(url,{ headers, withCredentials:true })
          .then(response => response.json())
          .then(data => compartidasTrans(data) || "")
        }
      // eslint-disable-next-line
      },[idCategory, withCategory]);

      async function compartidasTrans(compartidas){
        // console.log(compartidas);
        try {
          const TOKEN = window.localStorage.getItem("loggedToken")
          const url = `${process.env.REACT_APP_BACKEND_URL}/api/share/cat/${compartidas[0].id}/`;
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          };
      
          fetch(url,{ headers, withCredentials:true })
          .then(response => response.json())
          // .then(data => console.log(data))
          .then(data => setCategory(data.filter(tran => tran.id === idCategory)) || "")
        }
         catch (error) {
          console.error(error);
        }
      }

      // async function deleteTransac(){
      //   const TOKEN = window.localStorage.getItem("loggedToken")
      //   try {
      //     fetch(`${process.env.REACT_APP_BACKEND_URL}/api/transactions/del/${identificador}/`, {
      //     method: 'DELETE',
      //     headers: {
      //       'Content-Type': 'application/json',
      //       'Authorization': `Token ${JSON.parse(TOKEN)}`
      //     },
      //     withCredentials:true,
      //   });
      // } catch (error) {
      //   console.log(error);
      // }
      // }

      // async function CompareCategory(){

      // }

  return (
        <div className='table_row'>
            <p className='row_date'>{date}</p>
            <p className='row_category3'>{clave}</p>
            {/* <p className='row_category'>{withCategory ? `${category[0]?.name} (${walletName[0]?.name})` : `${transacName} (${walletName[0]?.name}) (compartida)`}</p> */}
            <p className='row_category'>${Number(profile_name).toLocaleString('es-MX')}</p>
            <p className='row_category2'>${Math.trunc(Number(total)).toLocaleString('es-MX')}</p>
            <p className='row_category'>${gastos.length === 0 ? Math.trunc(Number(total)).toLocaleString('es-MX') : gastos[0]?.original_total.toLocaleString('es-MX')}</p>
            {editable
              ?
              <>
                <div className="row_btns">
                  <button onClick={() => {setShowTransfer(true); setCategoryId(identificador)}} className='row_btn'>
                        <img style={{width:"11px"}} src={more_less} alt="delete transac" />
                  </button>
                  {/* <button onClick={() => {setShowModify(!showModify); setModifyId(identificador)}} className='row_btn'>
                      <img src={edit_row} alt="" />
                  </button> */}
                  <button onClick={() => {setShowConfirmation(!showConfirmation); setCategoryId(identificador); setApiUrl("")}} className='row_btn'>
                      <img src={delete_icon} alt="" />
                  </button>
                </div>
              </>
              : <div className="row_btns"></div>
            }
            {/* {loader ? <Loading /> : ""} */}
        </div>
  )
}

export default TablaDisponibles