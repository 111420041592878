// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #202741;
  transition: 0.4s;
  border-radius: 34px;
  border: 1px solid #59D8E7;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: #111820;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #DBEBF8;
}

input:checked + .slider:before {
  transform: translateX(25px);
}`, "",{"version":3,"sources":["webpack://./src/styles/Switch.scss"],"names":[],"mappings":"AAAA,2CAAA;AACA;EACI,kBAAA;EACA,qBAAA;EACA,WAAA;EACA,YAAA;AACJ;;AAEA,+BAAA;AACA;EACI,UAAA;EACA,QAAA;EACA,SAAA;AACJ;;AAEA,eAAA;AACA;EACI,kBAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,yBAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;EACA,yBAAA;EACA,gBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,2BAAA;AACJ","sourcesContent":["/* The switch - the box around the slider */\n.switch {\n    position: relative;\n    display: inline-block;\n    width: 50px;\n    height: 25px;\n}\n\n/* Hide default HTML checkbox */\n.switch input {\n    opacity: 0;\n    width: 0;\n    height: 0;\n}\n\n/* The slider */\n.slider {\n    position: absolute;\n    cursor: pointer;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: #202741;\n    transition: 0.4s;\n    border-radius: 34px;\n    border: 1px solid #59D8E7;\n}\n\n.slider:before {\n    position: absolute;\n    content: \"\";\n    height: 16px;\n    width: 16px;\n    left: 4px;\n    bottom: 4px;\n    background-color: #111820;\n    transition: 0.4s;\n    border-radius: 50%;\n}\n\ninput:checked + .slider {\n    background-color: #DBEBF8;\n}\n\ninput:checked + .slider:before {\n    transform: translateX(25px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
