import { useEffect, useState } from "react"
import { getMonths } from "../services/getMonths"

export function useMovimientosProd(){
    const [gastos, setGastos] = useState([])    
    useEffect(() => {
        getMonths()
        .then(data => {
            if(data?.months.length === 0){
                BringDatos(0)
            } else {
                BringDatos(Number(window.localStorage.getItem("monthId")))
            }
        })
        // .finally(        BringDatos())
        // eslint-disable-next-line
      },[])

      async function BringDatos(id){

        const PROFILE_ID = window.localStorage.getItem("profileId")  
        const TOKEN = window.localStorage.getItem("loggedToken")
      
        const formData = {month_id:id , id:Number(PROFILE_ID)}
        // console.log(formData);
      
        if(id === 0){
            setGastos([])
        } else {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/byprod/`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${JSON.parse(TOKEN)}`
                  },
                  withCredentials:true,
                  body: JSON.stringify(formData),
                });
                
                if (response.status === 200) {
                //   setLoader(false)
                  const data = await response.json();
                //   console.log(data);
                  setGastos(data);
        
                    // navigate("/home")
                  } else {
                    // setLoader(false)
                    // setShowLoginError(true)
                    console.error('Error submitting form');
                  }
                } catch (error) {
                  console.error('Error:', error);
                }
              }
      }

      return {gastos};
      
}