// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.website {
  background-color: #111820;
}

.website_light {
  background-color: #F6F8FC;
}

/* Scrollbar Track */
::-webkit-scrollbar {
  width: 5px; /* Width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  width: 5px; /* Color of the track */
}

::-webkit-scrollbar-thumb {
  background-color: #59D8E7; /* Color of the thumb */
  border-radius: 10px; /* Roundness of the thumb */
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAGA;EACE,SAAA;EACA,UAAA;EACA,sBAAA;EAEA,kCAAA;AADF;;AAIA;EACE,yBAAA;AADF;;AAGA;EACE,yBAAA;AAAF;;AAGA,oBAAA;AACA;EACE,UAAA,EAAA,kCAAA;AAAF;;AAGA;EACI,UAAA,EAAA,uBAAA;AAAJ;;AAGA;EACE,yBAAA,EAAA,uBAAA;EACA,mBAAA,EAAA,2BAAA;AAAF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');\n\n*{\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  // font-family: \"Inter\";\n  font-family: \"Poppins\", sans-serif;\n}\n\n.website{\n  background-color: #111820;\n}\n.website_light{\n  background-color: #F6F8FC;\n}\n\n/* Scrollbar Track */\n::-webkit-scrollbar {\n  width: 5px; /* Width of the entire scrollbar */\n}\n\n::-webkit-scrollbar-track {\n    width: 5px; /* Color of the track */\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: #59D8E7; /* Color of the thumb */\n  border-radius: 10px; /* Roundness of the thumb */\n  // border: 5px solid #f1f1f1; /* Padding around the thumb */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
