import React, { useState } from 'react'
import "../styles/AgregarCategoria.scss"
import cross_exit from "../assets/cross_exit.svg"


function AgregarComentario({show, setShow, identificador, comments}) {

    const [newComment, setNewComment] = useState("")
    const [showComment, setShowComment] = useState(false)
    const [showFile, setShowFile] = useState(false)
    const [file, setFile] = useState("")
    const [comment, setComment] = useState("")
    const [selectedFile, setSelectedFile] = useState(null); 
    
    const handleFileChange = (event) => { 
      setSelectedFile(event.target.files[0]); 
      console.log(event.target.files[0]);
      
    };

    async function handleCommentSubmit(e){
        e.preventDefault()
        setShow(!show)
        const TOKEN = window.localStorage.getItem("loggedToken")
        const formData = new FormData();
        
        // formData.append("file", selectedFile)
        // formData.append("comment", newComment)
        // formData.append("logcomment", newComment)
        
        // console.log(formCategoryData);
        if(selectedFile === null){
          // const formCategoryData = { comment: newComment, logcomment:newComment }
          formData.append("comment", newComment)
          formData.append("logcomment", newComment)
          try {
            if(window.localStorage.getItem("products")){             
              const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/comment/${identificador}/`, {
                method: 'POST',
                headers: {
                  // 'Content-Type': 'multipart/form-data',
                  'Authorization': `Token ${JSON.parse(TOKEN)}`
                },
                withCredentials:true,
                body: formData
              });
              if (response.status === 200) {
                // setLoader(false)
                console.log('Form submitted successfully');
                const data = await response.json();
                console.log(data);
                window.location.reload()
                } else {
                  // setLoader(false)
                  // setShowLoginError(true)
                  console.log(response.status);
                  console.error('Error submitting form');
                } 
            } else {
              const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/transactions/comment/${identificador}/`, {
                method: 'POST',
                headers: {
                  // 'Content-Type': 'application/json',
                  'Authorization': `Token ${JSON.parse(TOKEN)}`
                },
                withCredentials:true,
                body: formData
              });
              
              if (response.status === 200) {
                // setLoader(false)
                console.log('Form submitted successfully');
                const data = await response.json();
                console.log(data);
                window.location.reload()
                } else {
                  // setLoader(false)
                  // setShowLoginError(true)
                  console.log(response.status);
                  console.error('Error submitting form');
                }
            }
            } catch (error) {
              console.error('Error:', error);
            }
        } else {
          // const formCategoryData = {comment: newComment, logcomment:newComment }
          formData.append("file", selectedFile)
          formData.append("comment", newComment)
          formData.append("logcomment", newComment)
          // console.log(formData);
                  
          try {
            if(window.localStorage.getItem("products")){
              const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/comment/${identificador}/`, {
                method: 'POST',
                headers: {
                  // 'Content-Type': 'multipart/form-data',
                  'Authorization': `Token ${JSON.parse(TOKEN)}`
                },
                withCredentials:true,
                body: formData
              });
              
              if (response.status === 200) {
                // setLoader(false)
                console.log('Form submitted successfully');
                const data = await response.json();
                console.log(data);
                window.location.reload()
                } else {
                  // setLoader(false)
                  // setShowLoginError(true)
                  console.log(response.status);
                  console.error('Error submitting form');
                } 
            } else {
              // console.log(formData);
              // console.log(formCategoryData)
              const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/transactions/comment/${identificador}/`, {
                method: 'POST',
                headers: {
                  // 'Content-Type': 'multipart/form-data',
                  'Authorization': `Token ${JSON.parse(TOKEN)}`
                },
                withCredentials:true,
                body: formData
              });
              
              if (response.status === 200) {
                // setLoader(false)
                console.log('Form submitted successfully');
                const data = await response.json();
                console.log(data);
                window.location.reload()
                } else {
                  // setLoader(false)
                  // setShowLoginError(true)
                  console.log(response.status);
                  console.error('Error submitting form');
                }
            }
            } catch (error) {
              console.error('Error:', error);
            }
        }
  
    }

    async function deleteComment(comentario){
      setShowComment(!showComment)
      setComment(comentario)
    }
    async function deleteFile(archivo){
      setShowFile(!showComment)
      setFile(archivo)
    }

    async function DescargaArchivo(comment, archivo){
      const TOKEN = window.localStorage.getItem("loggedToken")
      const formData = new FormData()
      formData.append("comment", comment)
      try {
        if(window.localStorage.getItem("products")){
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/download_file/${identificador}/`, {
            method: 'POST',
            headers: {
              // 'Content-Type': 'multipart/form-data',
              'Authorization': `Token ${JSON.parse(TOKEN)}`
            },
            withCredentials:true,
            body: {comment:comment}
          });
          
          if (response.status === 200) {
            // setLoader(false)
            console.log('Form submitted successfully');
            const data = await response.json();
            console.log(data);
            window.location.reload()
            } else {
              // setLoader(false)
              // setShowLoginError(true)
              console.log(response.status);
              console.error('Error submitting form');
            } 
        } else {
          console.log(Object.fromEntries(formData))
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/transactions/download/${identificador}/`, {
            method: 'POST',
            headers: {
              // 'Content-Type': 'multipart/form-data',
              'Authorization': `Token ${JSON.parse(TOKEN)}`
            },
            withCredentials:true,
            body: formData
          });
          
          if (response.status === 200) {
            // setLoader(false)
            console.log('Form submitted successfully');
            const blob = await response.blob(); 
            const url = window.URL.createObjectURL(blob); 
            const a = document.createElement('a'); 
            a.href = url; 
            a.download = archivo.slice(19); 
            // Nombre del archivo a descargar 
            document.body.appendChild(a); 
            a.click(); 
            a.remove(); 
            window.URL.revokeObjectURL(url);
            // window.location.reload()
            } else {
              // setLoader(false)
              // setShowLoginError(true)
              console.log(response.status);
              console.error('Error submitting form');
            }
        }
        } catch (error) {
          console.error('Error:', error);
        }
    }

  return (
    <div className={window.localStorage.getItem("light") ? "add_comments_container_light":"add_comments_container"}>
        <form onSubmit={handleCommentSubmit}>
            <img className='close_cont' onClick={() => {setShow(!show)}} src={cross_exit} alt="close" />
            <label onClick={() => {console.log(comments)}}>Tus comentarios</label>
            {comments[0]?.comments[comments[0].comments.length-1] === ""
              ? ""
              : <ul>
              {comments[0]?.comments?.map((e, index) => (
                  <li key={index}>
                    <span>{e}</span>
                    <i onClick={() => {deleteComment(e)}} className="fa-solid fa-trash" style={{color: "#e32626"}}></i>
                  </li>
              ))
              }
              {Object.keys(comments[0].comment_files).map((key) => (
                  <li key={key}>
                    <span className='archivo' onClick={() =>{DescargaArchivo(key,comments[0].comment_files[key])}}>{comments[0].comment_files[key].slice(19)}</span>
                    <i onClick={() => {deleteFile(comments[0].comment_files[key])}} className="fa-solid fa-trash" style={{color: "#e32626"}}></i>
                  </li>
              ))
              }
          </ul>
            }
            
            <hr />
            <label htmlFor="nuevoComentario">+ Detalle de ingreso</label>
            <input 
                type="text"
                id='nuevoComentario'
                placeholder='Dinero por proyecto de...'
                required
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
            />
            <div className="flex_file">
              <label htmlFor="commentFile" className='file_label'>Agregar archivo</label>
              {selectedFile !== null ? <span className='file_name'>{selectedFile?.name}</span> : ""}
            </div>
            <input type="file"
              className='comment_file'
              multiple
              // accept="multipart/form-data"
              onChange={handleFileChange}
              id='commentFile'
            />
            <button type='submit'>Agregar</button>
        {showComment
          ? <ConfirmDeleteUser setConfirmDelete={setShowComment} comentario={comment} identificador={identificador} />
          : ""
        }
        {showFile
          ? <ConfirmDeleteFile setConfirmDelete={setShowFile} archivo={file} identificador={identificador} />
          : ""
        }
        </form>
    </div>
  )
}

export default AgregarComentario

function ConfirmDeleteUser({identificador, setConfirmDelete, comentario}){

  async function DeleteComment(){
    const TOKEN = window.localStorage.getItem("loggedToken")
    const formCategoryData = { comment: comentario }
    try {
      if(window.localStorage.getItem("products")){
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/rcomment/${identificador}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${JSON.parse(TOKEN)}`
        },
        withCredentials:true,
        body: JSON.stringify(formCategoryData)
      });
      if (response.status === 204 || response.status === 200) {
        // setLoader(false)
        console.log('Deleted successfully');
        window.location.reload()
        // const data = await response.json();
        // console.log(data);
      } else {
          console.log(response.status);
          // setLoader(false)
          // setShowLoginError(true)
          console.error('Error submitting form');
        }
      } else {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/transactions/rcomment/${identificador}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          },
          withCredentials:true,
          body: JSON.stringify(formCategoryData)
        });
        if (response.status === 204 || response.status === 200) {
          // setLoader(false)
          console.log('Deleted successfully');
          window.location.reload()
          // const data = await response.json();
          // console.log(data);
        } else {
            console.log(response.status);
            // setLoader(false)
            // setShowLoginError(true)
            console.error('Error submitting form');
          }
      }
    } catch (error) {
      console.error(error);
    }
  }

  return(
    <div className='delete_container'>
      <p>¿Desea eliminar este comentario?</p>
      <div className='flex_btns'>
        <span className='confirm_btn' onClick={DeleteComment}>Si</span>
        <span className='decline_btn' onClick={() => {setConfirmDelete(false)}}>No</span>
      </div>
    </div>
  )
}

function ConfirmDeleteFile({identificador, setConfirmDelete, archivo}){

  async function DeleteComment(){
    const TOKEN = window.localStorage.getItem("loggedToken")
    const formCategoryData = { file_path: archivo }
    try {
      if(window.localStorage.getItem("products")){
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/del_file/${identificador}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${JSON.parse(TOKEN)}`
        },
        withCredentials:true,
        body: JSON.stringify(formCategoryData)
      });
      if (response.status === 204 || response.status === 200) {
        // setLoader(false)
        console.log('Deleted successfully');
        window.location.reload()
        // const data = await response.json();
        // console.log(data);
      } else {
          console.log(response.status);
          // setLoader(false)
          // setShowLoginError(true)
          console.error('Error submitting form');
        }
      } else {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/transactions/del_file/${identificador}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          },
          withCredentials:true,
          body: JSON.stringify(formCategoryData)
        });
        if (response.status === 204 || response.status === 200) {
          // setLoader(false)
          console.log('Deleted successfully');
          window.location.reload()
          // const data = await response.json();
          // console.log(data);
        } else {
            console.log(response.status);
            // setLoader(false)
            // setShowLoginError(true)
            console.error('Error submitting form');
          }
      }
    } catch (error) {
      console.error(error);
    }
  }

  return(
    <div className='delete_container'>
      <p>¿Desea eliminar este archivo?</p>
      <div className='flex_btns'>
        <span className='confirm_btn' onClick={DeleteComment}>Si</span>
        <span className='decline_btn' onClick={() => {setConfirmDelete(false)}}>No</span>
      </div>
    </div>
  )
}