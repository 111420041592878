// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading_container {
  z-index: 1100;
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4823529412);
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading_container img {
  width: 8em;
}`, "",{"version":3,"sources":["webpack://./src/styles/Loading.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;EACA,aAAA;EACA,WAAA;EACA,6CAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AAAI;EACI,UAAA;AAER","sourcesContent":[".loading_container{\n    z-index: 1100;\n    position: absolute;\n    top: 0;\n    right: 0;\n    height: 100vh;\n    width: 100%;\n    background-color: #0000007b;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    img{\n        width: 8em;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
