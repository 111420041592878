import React, {useEffect, useRef, useState} from 'react'
// import Sidebar from './Sidebar'
import "../styles/SeleccionarCuenta.scss"
import { useLocation, useNavigate } from 'react-router-dom'

function SeleccionarCuenta() {

    const [wallets, setWallets] = useState([])
    // const [months, setMonths] = useState([])

    const navigate = useNavigate()
    const location = useLocation();
    const isMounted = useRef(false);
  
    useEffect(() => {
      if (!isMounted.current) {
        const token = window.localStorage.getItem("loggedToken");
        if (!token) {
            navigate("/login");
        }
  
        isMounted.current = true; // Configura el ref a true al final
    }
    }, [navigate, location.pathname]);

    useEffect(() => {
        // const PROFILE_ID = window.localStorage.getItem("profileId")
        const TOKEN = window.localStorage.getItem("loggedToken")
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/wallets/list/`;
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${JSON.parse(TOKEN)}`
        };
    
        fetch(url,{ headers, withCredentials:true })
        .then(response => response.json())
        .then(data => {if(data.length === 0){
            navigate("/cuentas")
        } else{setWallets(data)}})
        // eslint-disable-next-line
    },[])
    useEffect(() => {

        // eslint-disable-next-line
    },[])

    function handleLogout(){
        window.localStorage.clear()
        navigate("/login")
    } 
    
    async function HandleCuenta(id,isproduct){
        window.localStorage.setItem("walletId",id)
        // console.log(isproduct);
        
        
        const PROFILE_ID = window.localStorage.getItem("profileId")
        const TOKEN = window.localStorage.getItem("loggedToken")
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/months/${PROFILE_ID}/${id}/`;
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${JSON.parse(TOKEN)}`
        };
    
        await fetch(url,{ headers, withCredentials:true })
        .then(response => response.json())
        .then(data => {if(data.months.length === 0){
            navigate("/cuentas")
        } else{
            if(isproduct){
                window.localStorage.setItem("monthId",data.months[data.months.length - 1].id);
                window.localStorage.setItem("products",true);
                navigate("/home")
            } else {
                window.localStorage.setItem("monthId",data.months[data.months.length - 1].id);
                navigate("/home")
            }
        }})
        
    }

  return (
    <>
        <div className="cuenta_container">
            <div className="cuenta_subcontainer">
                <h1 onClick={() => {console.log(wallets);
                }}>Seleccionar Cuenta</h1>
                <ul className='cuenta_list'>
                    {
                        wallets?.map((cuenta) => (
                            <li key={cuenta.id} onClick={() => {HandleCuenta(cuenta.id,cuenta.isproduct)}} className='cuenta_item'>{cuenta.name}</li>
                        ))
                    }
                </ul>
            </div>
            <button onClick={handleLogout} className='cerrar_btn'>
                <svg className='arrow_back' width="8" height="12" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 9L5 5L1 1" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>   
                Cerrar Sesión
            </button>
        </div>
        {/* <Sidebar /> */}
    </>
  )
}

export default SeleccionarCuenta