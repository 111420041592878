import { useEffect, useState } from "react"
// import { getMonths } from "../services/getMonths"
import { getWallets } from "../services/getWallets"
import { getSharedWallets } from "../services/getSharedWallets"

export function useWalletName(){
    const [compartido, setCompartido] = useState(false)
    const [walletName, setWalletName] = useState([{name:""}])
 

    useEffect(() => {     
        getWallets()
        .then(data => CombineCategories(data?.filter(wall => wall.id === Number(window.localStorage.getItem("walletId")))))
  // eslint-disable-next-line
      },[])

      async function CombineCategories(local){

        if(local?.length === 0){
          setCompartido(true)
        }
          getSharedWallets()
          .then((data) => setWalletName(data?.concat(local).filter(wall => wall.id === Number(window.localStorage.getItem("walletId")))));

    }
    
      return {compartido, walletName};
      
}