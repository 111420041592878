import React, { useState } from 'react'
import "../styles/AgregarCategoria.scss"
import cross_exit from "../assets/cross_exit.svg"

function ModificarTransProd({montoTrans, fondoTrans, origenTrans, show, setShow, modifyId}) {

    // const [newOrigenName, setNewOrigenName] = useState(origenTrans)
    const [newMonto, setNewMonto] = useState(montoTrans)
    // const [newFondo, setNewFondo] = useState(fondoTrans)
    const [newComment, setNewComment] = useState("")
    // const [newIconName, /*setIconName*/] = useState("fa-house")

    // const [icon1, setIcon1] = useState(false);
    // const [icon2, setIcon2] = useState(false);
    // const [icon3, setIcon3] = useState(false);
    // const [icon4, setIcon4] = useState(false);
    // const [icon5, setIcon5] = useState(false);
    // const [icon6, setIcon6] = useState(false);
    // const [icon7, setIcon7] = useState(false);
    // const [icon8, setIcon8] = useState(false);
    // const [icon9, setIcon9] = useState(false);
    // const [icon10, setIcon10] = useState(false);
    // const [icon11, setIcon11] = useState(false);
    // const [icon12, setIcon12] = useState(false);
    

    async function handleCategorySubmit(e){
      e.preventDefault();
      setShow(!show)
      const TOKEN = window.localStorage.getItem("loggedToken")
      const newName = {currAmount:Number(newMonto), comment:newComment}

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/update/${modifyId}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          },
          withCredentials:true,
          body: JSON.stringify(newName)
        });
        
        if (response.status === 200) {
          // setLoader(false)
          console.log('Form submitted successfully');
          const data = await response.json();
          console.log(data);
          window.location.reload()
          } else {
            // setLoader(false)
            // setShowLoginError(true)
            console.error('Error submitting form');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    }

    // function HandleClass1(){
    //   setIconName("fa-house")
    //   setIcon1(true)
    //   setIcon2(false)
    //   setIcon3(false)
    //   setIcon4(false)
    //   setIcon5(false)
    //   setIcon6(false)
    //   setIcon7(false)
    //   setIcon8(false)
    //   setIcon9(false)
    //   setIcon10(false)
    //   setIcon11(false)
    //   setIcon12(false)
    // }
    // function HandleClass2(){
    //   setIconName("fa-money-bill")
    //   setIcon1(false)
    //   setIcon2(true)
    //   setIcon3(false)
    //   setIcon4(false)
    //   setIcon5(false)
    //   setIcon6(false)
    //   setIcon7(false)
    //   setIcon8(false)
    //   setIcon9(false)
    //   setIcon10(false)
    //   setIcon11(false)
    //   setIcon12(false)
    // }
    // function HandleClass3(){
    //   setIconName("fa-shirt")
    //   setIcon1(false)
    //   setIcon2(false)
    //   setIcon3(true)
    //   setIcon4(false)
    //   setIcon5(false)
    //   setIcon6(false)
    //   setIcon7(false)
    //   setIcon8(false)
    //   setIcon9(false)
    //   setIcon10(false)
    //   setIcon11(false)
    //   setIcon12(false)
    // }
    // function HandleClass4(){
    //   setIconName("fa-building")
    //   setIcon1(false)
    //   setIcon2(false)
    //   setIcon3(false)
    //   setIcon4(true)
    //   setIcon5(false)
    //   setIcon6(false)
    //   setIcon7(false)
    //   setIcon8(false)
    //   setIcon9(false)
    //   setIcon10(false)
    //   setIcon11(false)
    //   setIcon12(false)
    // }
    // function HandleClass5(){
    //   setIconName("fa-credit-card")
    //   setIcon1(false)
    //   setIcon2(false)
    //   setIcon3(false)
    //   setIcon4(false)
    //   setIcon5(true)
    //   setIcon6(false)
    //   setIcon7(false)
    //   setIcon8(false)
    //   setIcon9(false)
    //   setIcon10(false)
    //   setIcon11(false)
    //   setIcon12(false)
    // }
    // function HandleClass6(){
    //   setIconName("fa-school")
    //   setIcon1(false)
    //   setIcon2(false)
    //   setIcon3(false)
    //   setIcon4(false)
    //   setIcon5(false)
    //   setIcon6(true)
    //   setIcon7(false)
    //   setIcon8(false)
    //   setIcon9(false)
    //   setIcon10(false)
    //   setIcon11(false)
    //   setIcon12(false)
    // }
    // function HandleClass7(){
    //   setIconName("fa-hospital")
    //   setIcon1(false)
    //   setIcon2(false)
    //   setIcon3(false)
    //   setIcon4(false)
    //   setIcon5(false)
    //   setIcon6(false)
    //   setIcon7(true)
    //   setIcon8(false)
    //   setIcon9(false)
    //   setIcon10(false)
    //   setIcon11(false)
    //   setIcon12(false)
    // }
    // function HandleClass8(){
    //   setIconName("fa-van-shuttle")
    //   setIcon1(false)
    //   setIcon2(false)
    //   setIcon3(false)
    //   setIcon4(false)
    //   setIcon5(false)
    //   setIcon6(false)
    //   setIcon7(false)
    //   setIcon8(true)
    //   setIcon9(false)
    //   setIcon10(false)
    //   setIcon11(false)
    //   setIcon12(false)
    // }
    // function HandleClass9(){
    //   setIconName("fa-steak")
    //   setIcon1(false)
    //   setIcon2(false)
    //   setIcon3(false)
    //   setIcon4(false)
    //   setIcon5(false)
    //   setIcon6(false)
    //   setIcon7(false)
    //   setIcon8(false)
    //   setIcon9(true)
    //   setIcon10(false)
    //   setIcon11(false)
    //   setIcon12(false)
    // }
    // function HandleClass10(){
    //   setIconName("fa-plane")
    //   setIcon1(false)
    //   setIcon2(false)
    //   setIcon3(false)
    //   setIcon4(false)
    //   setIcon5(false)
    //   setIcon6(false)
    //   setIcon7(false)
    //   setIcon8(false)
    //   setIcon9(false)
    //   setIcon10(true)
    //   setIcon11(false)
    //   setIcon12(false)
    // }
    // function HandleClass11(){
    //   setIconName("fa-bag-shopping")
    //   setIcon1(false)
    //   setIcon2(false)
    //   setIcon3(false)
    //   setIcon4(false)
    //   setIcon5(false)
    //   setIcon6(false)
    //   setIcon7(false)
    //   setIcon8(false)
    //   setIcon9(false)
    //   setIcon10(false)
    //   setIcon11(true)
    //   setIcon12(false)
    // }
    // function HandleClass12(){
    //   setIconName("fa-people-simple")
    //   setIcon1(false)
    //   setIcon2(false)
    //   setIcon3(false)
    //   setIcon4(false)
    //   setIcon5(false)
    //   setIcon6(false)
    //   setIcon7(false)
    //   setIcon8(false)
    //   setIcon9(false)
    //   setIcon10(false)
    //   setIcon11(false)
    //   setIcon12(true)
    // }

  return (
    <div className={window.localStorage.getItem("light") ? "add_category_container_light":"add_category_container"}>
        <form onSubmit={handleCategorySubmit}>
            <img onClick={() => setShow(!show)} src={cross_exit} alt="close" />
            <label htmlFor="nuevoOrigen">Monto abonado</label>
            <input 
                type="text"
                id='nuevoOrigen'
                placeholder='xxxxxx'
                required
                value={newMonto}
                onChange={(e) => setNewMonto(e.target.value)}
            />
            <label htmlFor="nuevoComentario">Comentario (opcional)</label>
            <input 
                type="text"
                id='nuevoComentario'
                placeholder='Comentario...'
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
            />
            {/* <label>Seleccionar icono</label> */}
            {/* <ul className='flex_icons'>
              <li className={icon1 ? "selected" : "not_selected"} onClick={HandleClass1}><i className="fa-regular fa-house"></i></li>
              <li className={icon2 ? "selected" : "not_selected"} onClick={HandleClass2}><i className="fa-regular fa-money-bill"></i></li>
              <li className={icon3 ? "selected" : "not_selected"} onClick={HandleClass3}><i className="fa-regular fa-shirt"></i></li>
              <li className={icon4 ? "selected" : "not_selected"} onClick={HandleClass4}><i className="fa-regular fa-building"></i></li>
              <li className={icon5 ? "selected" : "not_selected"} onClick={HandleClass5}><i className="fa-regular fa-credit-card"></i></li>
              <li className={icon6 ? "selected" : "not_selected"} onClick={HandleClass6}><i className="fa-regular fa-school"></i></li>
              <li className={icon7 ? "selected" : "not_selected"} onClick={HandleClass7}><i className="fa-regular fa-hospital"></i></li>
              <li className={icon8 ? "selected" : "not_selected"} onClick={HandleClass8}><i className="fa-regular fa-van-shuttle"></i></li>
              <li className={icon9 ? "selected" : "not_selected"} onClick={HandleClass9}><i className="fa-regular fa-steak"></i></li>
              <li className={icon10 ? "selected" : "not_selected"} onClick={HandleClass10}><i className="fa-regular fa-plane"></i></li>
              <li className={icon11 ? "selected" : "not_selected"} onClick={HandleClass11}><i className="fa-regular fa-bag-shopping"></i></li>
              <li className={icon12 ? "selected" : "not_selected"} onClick={HandleClass12}><i className="fa-regular fa-people-simple"></i></li>
            </ul> */}
            <button type='submit' >Guardar</button>
        </form>
    </div>
  )
}

export default ModificarTransProd