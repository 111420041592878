import React from 'react'
import "../styles/CambiarContraseña.scss"
import { Link } from 'react-router-dom'


function CambiarContraseña() {
  return (
    <div className='cambiar_contraseña'>
      {/* <div className="left_bg"></div> */}
        <form action="">
            <h1>Cambiar Contraseña</h1>
            <p>Te enviaremos un correo para restaurar tu clave</p>
            <label>Ingresa tu correo electrónico</label>
            <input type="email" placeholder='Correo electrónico...'required/>
            <button type='submit'>Enviar</button>
            <div className='crear_cuenta'>
              <Link to="/login"><p>Volver</p></Link>
            </div>
        </form>

    </div>
  )
}

export default CambiarContraseña