import React, { useEffect, useRef, useState } from 'react'
import Header from './Header'
import Sidebar from './Sidebar'
import "../styles/Cuentas.scss"
import cross_exit from "../assets/cross_exit.svg";
import { getWallets } from '../services/getWallets'
import RegistroBitacora from './RegistroBitacora'
import { getSharedWallets } from '../services/getSharedWallets'
import { useLocation, useNavigate } from 'react-router-dom'

function Bitacora() {

    const [registros, setRegistros] = useState()
    const [billeteras, setBilleteras] = useState()
    const [billeterasComparidas, setBilleterasCompartidas] = useState()
    const [showContra, setShowContra] = useState(false)
    const [passwordAction, setPasswordAction] = useState("")

    const navigate = useNavigate()
    const location = useLocation();
    const isMounted = useRef(false);
  
    useEffect(() => {
      if (!isMounted.current) {
        const token = window.localStorage.getItem("loggedToken");
        if (!token) {
            navigate("/login");
        }
  
        isMounted.current = true; // Configura el ref a true al final
    }
    }, [navigate, location.pathname]);

    useEffect(() => {
        const TOKEN = window.localStorage.getItem("loggedToken")
        const WALLET_ID = window.localStorage.getItem("walletId")
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/logs/${WALLET_ID}/`;
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${JSON.parse(TOKEN)}`
        };

        fetch(url, { headers, withCredentials: true })
        .then(response => response.json())
        .then(data => {setRegistros(data); console.log(data)})
    
        // try {
        //     const response = fetch(url, { headers, withCredentials: true });
        //     if (!response.ok) {
        //         throw new Error(`HTTP error! Status: ${response.status}`);
        //     }
        //     const data = response.json();
        //     return console.log(data);
        // } catch (error) {
        //     console.error(error);
        //     return null;
        // }
    },[])

    useEffect(() => {
        getWallets()
        .then(data => setBilleteras(data))
    },[])
    useEffect(() => {
        getSharedWallets()
        .then(data => setBilleterasCompartidas(data))
    },[])

  return (
    <>
        <Header />
        <Sidebar 
        setShow={setShowContra} 
        setPasswordAction={setPasswordAction}
        />
        <div className={window.localStorage.getItem("light") ? "cuentas_container_light":"cuentas_container"}>
            <div className="title_flex">
                <h2 className='cuentas_title'>Historial</h2>
            </div>
            <div className="bitacora_container">
                <ul>
                    {registros?.map((reg) => (
                        <RegistroBitacora 
                            key={reg.id}
                            id={reg.id}
                            idBilletera={reg.idWallet}
                            fecha={reg.date}
                            accion={reg.action}
                            usuario={reg.user}
                            comentario={reg.comment}
                            billeteras={billeteras}
                            billeterasComparidas={billeterasComparidas}
                        />
                    ))}
                </ul>
            </div>
        {/* <Resumen /> 
        <ResumenProductos />  */}
        </div>
        {showContra ? <IngresarContraseña show={showContra} setShow={setShowContra} passwordAction={passwordAction} /> : ""}
    </>
  )
}

export default Bitacora

function IngresarContraseña({show, setShow, passwordAction}) {


    const [password, setPassword] = useState("")
    const [showContraError, setShowContraError] = useState(false)
  
    const navigate = useNavigate()
  
    async function handleContraSubmit(e){
      e.preventDefault();
      const TOKEN = window.localStorage.getItem("loggedToken")
      const formCategoryData = {password:password}
      
      // console.log(formCategoryData);
  
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/pass/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(TOKEN)}`
          },
          withCredentials:true,
          body: JSON.stringify(formCategoryData)
        });
        
        if (response.status === 200) {
          // setLoader(false)
          console.log('Form submitted successfully');
          const data = await response.json();
          console.log(data.detail);
          window.localStorage.setItem("aproved", true)
          // setShow(!show)
          // setAllowAction(true)
          // window.location.reload()
          if(passwordAction === "categorias"){
            navigate("/categorias")
          } else if (passwordAction === "automatizaciones") {          
            navigate("/automatizaciones")
          }
          } else {
            // setLoader(false)
            setShowContraError(true)
            console.log(response.status);
            setPassword("")
            
            console.error('Error submitting form');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    }
  
  return (
    <div className={window.localStorage.getItem("light") ? "add_category_container_light":"add_category_container"}>
        <form onSubmit={handleContraSubmit}>
            <img onClick={() => setShow(!show)} src={cross_exit} alt="close" />
            <label htmlFor="contraseña">Ingrese contraseña</label>
            {showContraError
            ? <span style={{color: "#e74504", fontSize:"14px"}}>Contraseña Incorrecta</span>
            : ""
            }
            <input 
                type="password"
                id='contraseña'
                placeholder='Contraseña...'
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <button type='submit' >Guardar</button>
        </form>
    </div>
  )
  }