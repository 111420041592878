import React, { useState, useEffect } from 'react'
// import noImage from "../assets/no-image.webp"
import "../styles/SeleccionarPerfil.scss"
import { Link } from 'react-router-dom'
import cross_icon from "../assets/cross_icon.svg"





function SeleccionarPerfil({showProfiles, setShowProfiles}) {

  const [perfiles, setPerfiles] = useState([]);
  const [perfilPrincipal, setPerfilPrincipal] = useState([{name:""}]);
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [identificador, setIdentificador] = useState(0)
  const [nombrePerfil, setNombrePerfil] = useState("")

  // const navigate = useNavigate();


  useEffect(() => {

    const PROFILE_ID = window.localStorage.getItem("profileId")
    const TOKEN = window.localStorage.getItem("loggedToken")
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/profiles/`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${JSON.parse(TOKEN)}`
    };

    fetch(url,{ headers, withCredentials:true })
    .then(response => response.json())
    // .then(data => console.log(data))
    .then(data => setPerfiles(data.filter(user => user.id !== Number(PROFILE_ID))))
},[])
  useEffect(() => {

    const PROFILE_ID = window.localStorage.getItem("profileId")
    const TOKEN = window.localStorage.getItem("loggedToken")
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/profiles/`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${JSON.parse(TOKEN)}`
    };

    fetch(url,{ headers, withCredentials:true })
    .then(response => response.json())
    .then(data => setPerfilPrincipal(data.filter(user => user.id === Number(PROFILE_ID))))
},[])

function ChangeUser(id){

  window.localStorage.removeItem("profileId")
  window.localStorage.setItem(
    "profileId", JSON.stringify(id)
  )
  window.location.reload();
}

  return (
    <div className='select_container'>
        <div className='select_title'>
            <img src={`${process.env.REACT_APP_BACKEND_URL}${perfilPrincipal[0].image}`} alt="profile" />
            <p>{perfilPrincipal[0].name}</p>
            <img onClick={() => {setShowProfiles(false)}} className='perfil_cross' src={cross_icon} alt="cerrar" />
        </div>
        <div className='select_profiles'>
            <ul>
              {perfiles.map((user) => (
                <li  className='item_perfil' key={user.id}>
                  {/* <img src={`${process.env.REACT_APP_BACKEND_URL}${user.image}`} alt='user profile' /> */}
                  <span onClick={()=>{ChangeUser(user.id)}}>{user.name}</span>
                  {/* <img onClick={()=>{DeleteUser(user.id)}} className='perfil_cross' src={cross_icon} alt="borrar usuario" /> */}
                  <i onClick={()=>{setNombrePerfil(user.name);setIdentificador(user.id);setConfirmDelete(!confirmDelete)}} className="fa-solid fa-trash" style={{color: "#e32626"}}></i>
                </li>
              ))}
            </ul>
            <Link to="/crear-perfil">
              <button className='crear_perfil'>Crear nuevo perfil</button>
            </Link>
        </div>
        {confirmDelete
          ? <ConfirmDeleteUser identificador={identificador} setConfirmDelete={setConfirmDelete} nombrePerfil={nombrePerfil} />
          : ""
        }
    </div>
  )
}

export default SeleccionarPerfil

function ConfirmDeleteUser({identificador, setConfirmDelete, nombrePerfil}){

  async function DeleteUser(){
    const TOKEN = window.localStorage.getItem("loggedToken")
    try {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/profiles/del/${identificador}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${JSON.parse(TOKEN)}`
      },
      withCredentials:true,
    });
    window.location.reload();
  } catch (error) {
    console.log(error);
  }
  }

  return(
    <div className='delete_container'>
      <p>¿Seguro quiere eliminar el perfil de "{nombrePerfil}"?</p>
      <div className='flex_btns'>
        <button className='confirm_btn' onClick={DeleteUser}>Si</button>
        <button className='decline_btn' onClick={() => {setConfirmDelete(false)}}>No</button>
      </div>
    </div>
  )
}
